import React, { useState } from 'react';
import Rating from '../Rating';

export default function Testimonial() {
  const [activeTab, setActiveTab] = useState(2);
  const handleTabClick = tabNumber => {
    setActiveTab(tabNumber);
  };
  return (
    <div className="cs_tabs cs_style1">
      <ul className="cs_tab_links">
        <li className={activeTab === 1 ? "active" : ""}>
          <div className="cs_tab_link_in" onClick={() => handleTabClick(1)}>
            <div className="cs_testimonial_1_avatar">
              <img src="/images/home_1/avatar_1.png" alt="Avatar" />
              <div className="cs_testimonial_1_avatar_right">
                <h3 className="cs_fs_24 cs_semibold mb-0">PAULO HUBERT</h3>
                <p className="cs_heading_color mb-0">Veron, punta cana</p>
              </div>
            </div>
          </div>
        </li>
        <li className={activeTab === 2 ? "active" : ""}>
          <div className="cs_tab_link_in" onClick={() => handleTabClick(2)}>
            <div className="cs_testimonial_1_avatar">
              <img src="/images/home_1/avatar_2.png" alt="Avatar" />
              <div className="cs_testimonial_1_avatar_right">
                <h3 className="cs_fs_24 cs_semibold mb-0">LAURENCE VENDETTA</h3>
                <p className="cs_heading_color mb-0">D.N, Santo Domingo</p>
              </div>
            </div>
          </div>
        </li>
        <li className={activeTab === 3 ? "active" : ""}>
          <div className="cs_tab_link_in" onClick={() => handleTabClick(3)}>
            <div className="cs_testimonial_1_avatar">
              <img src="/images/home_1/avatar_3.png" alt="Avatar" />
              <div className="cs_testimonial_1_avatar_right">
                <h3 className="cs_fs_24 cs_semibold mb-0">CASSANDRA RAUL</h3>
                <p className="cs_heading_color mb-0">Santiago de los caballeros</p>
              </div>
            </div>
          </div>
        </li>
      </ul>
      <div className="cs_tab_body">
        {activeTab === 1 && (
          <div className="cs_testimonial cs_style_1">
            <img src="/images/icons/quote.svg" alt="Icon" />
            <p>
              El técnico fue excelente y lo hizo sentir cómodo durante todo el
              proceso, y el equipo fue amable y profesional. Hace poco tuve que
              solicitar los servicios de INGET AGUA para una revisión de nuestro
              sistema de osmosis inversa, y quedé muy impresionado con la
              atención y el servicio que recibimos.
            </p>
            <Rating ratingNumber={5} />
          </div>
        )}
        {activeTab === 2 && (
          <div className="cs_testimonial cs_style_1">
            <img src="/images/icons/quote.svg" alt="Icon" />
            <p>
              El técnico fue excelente con él y lo hizo sentir cómodo, y todo el
              equipo fue amable y atento. Recientemente tuve que contactar a
              INGET AGUA para un chequeo de mantenimiento de nuestro sistema de
              filtración de agua, y quedé muy impresionado con la calidad del
              servicio que recibimos.
            </p>
            <Rating ratingNumber={4.5} />
          </div>
        )}
        {activeTab === 3 && (
          <div className="cs_testimonial cs_style_1">
            <img src="/images/icons/quote.svg" alt="Icon" />
            <p>
              Quedé muy impresionado con el servicio que recibí. El técnico fue
              excelente, lo hizo sentir cómodo y todo el equipo fue amable y
              atento. Recientemente tuve que contactar a INGET AGUA para una
              revisión menor del sistema de filtración.
            </p>
            <Rating ratingNumber={4.5} />
          </div>
        )}
      </div>
    </div>
  );
}
