// import { Icon } from "@iconify/react";
import React, { useState } from "react";
// import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default function AppointmentForm() {
  // const [selectedDate, setSelectedDate] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    reasonForVisit: "servicio y reparación", // Set default value
    department: "presupuesto - (Cotización)", // Set default value
  });

  const onSubmit = (e) => {
    e.preventDefault();
    const data = {
      ...formData,
    };

    const createMessage = ({
      name,
      phone,

      reasonForVisit,
      department,
    }) => {
      return `¡Hola ${name}!, ${phone} Tu cita está programada para ser atendida al instante en el departamento de ${department}. Motivo de la visita: ${reasonForVisit}. Si necesitas cambiar tu número de contacto, llámanos al +18293691501. ¡Te esperamos!`;
    };
    const message = createMessage(data);
    const encodedMessage = encodeURIComponent(message);
    const urlWhatsApp = `https://wa.me/18293691501?text=${encodedMessage}`;

    return (window.location.href = urlWhatsApp);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <form className="row" onSubmit={onSubmit}>
      <div className="col-lg-6">
        <label className="cs_input_label cs_heading_color">Nombre</label>
        <input
          type="text"
          name="name"
          className="cs_form_field"
          placeholder="David John"
          value={formData.name}
          onChange={handleInputChange}
        />
        <div className="cs_height_42 cs_height_xl_25" />
      </div>
      <div className="col-lg-6">
        <label className="cs_input_label cs_heading_color">
          Número de teléfono
        </label>
        <input
          type="text"
          name="phone"
          className="cs_form_field"
          placeholder="(123) 456 - 789"
          value={formData.phone}
          onChange={handleInputChange}
        />
        <div className="cs_height_42 cs_height_xl_25" />
      </div>
      {/* <div className="col-lg-12">
        <label className="cs_input_label cs_heading_color">
          Fecha preferida
        </label>
        <div className="cs_with_icon_input">
          <DatePicker
            selected={selectedDate}
            onChange={(date) => setSelectedDate(date)}
            dateFormat="dd/MM/yyyy"
            minDate={new Date()}
            isClearable
            placeholderText="dd/mm/yyyy"
            name="date"
          />
          <i>
            <Icon icon="fa6-solid:calendar-days" />
          </i>
        </div>
        <div className="cs_height_42 cs_height_xl_25" />
      </div> */}
      <div className="col-lg-12">
        <label className="cs_input_label cs_heading_color">
          Motivo de la cita
        </label>
        <div className="cs_radio_group">
          <div className="cs_radio_wrap">
            <input
              className="cs_radio_input"
              type="radio"
              name="reasonForVisit"
              id="routineCheckup"
              value="levantamiento"
              checked={formData.reasonForVisit === "levantamiento"}
              onChange={handleInputChange}
            />
            <label className="cs_radio_label" htmlFor="routineCheckup">
              Levantamiento
            </label>
          </div>
          <div className="cs_radio_wrap">
            <input
              className="cs_radio_input"
              type="radio"
              name="reasonForVisit"
              id="newPatientVisit"
              value="servicio y reparación"
              checked={formData.reasonForVisit === "servicio y reparación"}
              onChange={handleInputChange}
            />
            <label className="cs_radio_label" htmlFor="newPatientVisit">
              Servicio y reparación
            </label>
          </div>
          <div className="cs_radio_wrap">
            <input
              className="cs_radio_input"
              type="radio"
              name="reasonForVisit"
              id="specificConcern"
              value="preocupación específica"
              checked={formData.reasonForVisit === "preocupación específica"}
              onChange={handleInputChange}
            />
            <label className="cs_radio_label" htmlFor="specificConcern">
              Preocupación específica
            </label>
          </div>
        </div>
        <div className="cs_height_42 cs_height_xl_25" />
      </div>
      <div className="col-lg-12">
        <label className="cs_input_label cs_heading_color">Departamentos</label>
        <div className="cs_radio_group">
          <div className="cs_radio_wrap">
            <input
              className="cs_radio_input"
              type="radio"
              name="department"
              id="pediatric"
              value="ingeniería"
              checked={formData.department === "ingeniería"}
              onChange={handleInputChange}
            />
            <label className="cs_radio_label" htmlFor="pediatric">
              Ingeniería
            </label>
          </div>
          <div className="cs_radio_wrap">
            <input
              className="cs_radio_input"
              type="radio"
              name="department"
              id="obstetricsGynecology"
              value="presupuesto - (Cotización)"
              checked={formData.department === "presupuesto - (Cotización)"}
              onChange={handleInputChange}
            />
            <label className="cs_radio_label" htmlFor="obstetricsGynecology">
              Presupuesto (Cotización)
            </label>
          </div>
          <div className="cs_radio_wrap">
            <input
              className="cs_radio_input"
              type="radio"
              name="department"
              id="cardiology"
              value="finanzas"
              checked={formData.department === "finanzas"}
              onChange={handleInputChange}
            />
            <label className="cs_radio_label" htmlFor="cardiology">
              Finanzas
            </label>
          </div>
        </div>
        <div className="cs_height_42 cs_height_xl_25" />
      </div>
      <div className="col-lg-12">
        <button type="submit" className="cs_btn cs_style_1">
          <span>Solicitar</span>
          <i>
            <img src="/images/icons/arrow_white.svg" alt="Icon" />
            <img src="/images/icons/arrow_white.svg" alt="Icon" />
          </i>
        </button>
      </div>
    </form>
  );
}
