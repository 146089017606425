import React from 'react';
import ContactInfoWidget from '../Widget/ContactInfoWidget';
import MenuWidget from '../Widget/MenuWidget';
import SocialWidget from '../Widget/SocialWidget';
import Newsletter from '../Widget/Newsletter';
import TextWidget from '../Widget/TextWidget';
const menuDataOne = [
  { title: 'Nosotros', href: '/' },
  { title: 'Departamentos', href: '/' },
  { title: 'Ingenieros', href: '/' },
  { title: 'Citas', href: '/citas' },
  { title: 'Testimonios', href: '/' },
];
const menuDataTwo = [
  { title: 'Blog', href: '/' },
  { title: 'Contactanos', href: '/' },
  { title: 'FAQs', href: '/' },
  { title: 'Política y privacidad', href: '/' },
  { title: 'Termino y condiciones', href: '/' },
];

export default function Footer() {
  return (
    <footer className="cs_footer cs_style_1 cs_heading_color">
      <div
        className="cs_footer_logo_wrap"
        style={{ backgroundImage: "url(/images/footer_bg_1.svg)" }}
      >
        <div
          className="cs_footer_brand"
          style={{ backgroundImage: "url(/images/footer_logo_bg.svg)" }}
        >
          <img
            src="/images/LogoIngetAguaSolo.png"
            alt="Logo Icon"
            className="cs_footer_brand_icon"
            height={60}
          />
          <h2 className="cs_footer_brand_text">Inget Agua</h2>
        </div>
      </div>
      <div className="cs_footer_main">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="cs_footer_item">
                <TextWidget text="Ingeniería & <br />centro de tecnología" />
                <ContactInfoWidget />
              </div>
            </div>
            <div className="col-lg-2">
              <div className="cs_footer_item">
                <MenuWidget data={menuDataOne} />
              </div>
            </div>
            <div className="col-lg-2">
              <div className="cs_footer_item">
                <MenuWidget data={menuDataTwo} />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="cs_footer_item">
                <Newsletter
                  title="Sean Nuestros suscriptores"
                  subTitle="Para recibir las últimas noticias sobre salud de nuestros expertos"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="cs_footer_bottom cs_accent_bg">
        <div className="container">
          <div className="cs_footer_bottom_in">
            <SocialWidget />
            <div className="cs_copyright">
              Copyright © 2024 Inget Agua. Todos los derechos reservados.
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
