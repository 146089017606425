import React from "react";
import Hero from "../Hero";
import AboutSection from "../Section/AboutSection";
import BrandsSection from "../Section/BrandsSection";
import Banner from "../Section/BannerSection";
import Section from "../Section";
import FeaturesSection from "../Section/FeaturesSection";
import TestimonialSection from "../Section/TestimonialSection";
// import BlogSection from "../Section/BlogSection";
import AppointmentSection from "../Section/AppointmentSection";
import FaqSection from "../Section/FaqSection";
// import AwardSection from "../Section/AwardSection";
import DepartmentSection from "../Section/DepartmentSection";
import { pageTitle } from "../../helpers/PageTitle";
const featureListData = [
  {
    iconSrc: "/images/home_1/compassion.svg",
    title: "Compasión",
    subTitle:
      "Entendemos que elegir un sistema de purificación de agua puede ser un proceso complejo, por lo que nos esforzamos por ofrecer un entorno confiable y un servicio de apoyo que brinde tranquilidad a nuestros clientes y seguridad en la calidad del agua para todos.",
  },
  {
    iconSrc: "/images/home_1/excellence.svg",
    title: "Excelencia",
    subTitle:
      "Estamos comprometidos a proporcionar soluciones de purificación de agua de la más alta calidad a nuestros clientes. Creemos en la mejora continua de nuestras tecnologías, conocimientos y recursos para garantizar que ofrecemos agua pura y segura con los mejores estándares posibles.",
  },
  {
    iconSrc: "/images/home_1/integrity.svg",
    title: "Integridad",
    subTitle: `Creemos en ofrecer soluciones de purificación de agua con integridad y honestidad. Somos transparentes en nuestros procesos de comunicación y toma de decisiones, y siempre anteponemos los intereses de nuestros clientes, brindando la mejor solución para garantizar agua segura y de calidad.`,
  },
  {
    iconSrc: "/images/home_1/respect.svg",
    title: "Respecto",
    subTitle:
      "Tratamos a todos nuestros clientes con respeto y dignidad, independientemente de sus necesidades o circunstancias. Creemos que cada persona merece recibir soluciones de purificación de agua con atención, compromiso y amabilidad.",
  },
  {
    iconSrc: "/images/home_1/teamwork.svg",
    title: "Trabajo en equipo",
    subTitle:
      "Creemos en trabajar en colaboración con nuestro equipo y otros profesionales del agua para proporcionar soluciones integrales y efectivas en la purificación y tratamiento del agua a nuestros clientes.",
  },
];
const brandData = [
  { imgUrl: "images/brand_1.png", imgAlt: "Toray" },
  { imgUrl: "images/brand_2.png", imgAlt: "ResinTech Inc" },
  { imgUrl: "images/brand_3.png", imgAlt: "Water Quality" },
  { imgUrl: "images/brand_4.png", imgAlt: "Blue white" },
  { imgUrl: "images/brand_5.png", imgAlt: "Pedrollo" },
  { imgUrl: "images/brand_6.png", imgAlt: "Aquatrol" },
  { imgUrl: "images/brand_7.png", imgAlt: "Procon" },
  { imgUrl: "images/brand_8.png", imgAlt: "United Salt Corp" },
];
const faqData = [
  {
    title: "¿Qué servicios ofrece INGET AGUA?",
    content:
      "INGET AGUA se especializa en una variedad de soluciones para el tratamiento de agua, incluyendo la instalación de sistemas de filtración, osmosis inversa y ablandadores de agua. Ofrecemos mantenimiento completo, chequeos técnicos y soluciones personalizadas para garantizar agua limpia y segura en hogares, negocios e industrias.",
  },
  {
    title: "¿Cómo hago una cita con INGET AGUA?",
    content:
      "Para programar una cita con INGET AGUA, puedes contactarnos directamente por teléfono o WhatsApp, o visitar nuestra página web. Nuestro equipo de expertos estará disponible para coordinar una consulta y brindarte las mejores soluciones en tratamiento de agua según tus necesidades. ¡Estamos listos para ayudarte!",
  },
  {
    title: "¿Aceptan financiamiento?",
    content:
      "En INGET AGUA, ofrecemos opciones de financiamiento para facilitar la instalación de nuestros sistemas de tratamiento de agua. Nos adaptamos a tus necesidades con planes de pago flexibles para que puedas disfrutar de agua pura y segura sin comprometer tu presupuesto. ¡Contáctanos para más detalles!",
  },
  {
    title: "¿Qué debo llevar a mi cita?",
    content:
      "Para tu cita con INGET AGUA, es útil que tengas a mano información sobre tu sistema de agua actual, como facturas recientes del servicio de agua, detalles sobre el equipo que ya tienes instalado, y cualquier inquietud específica que tengas sobre la calidad del agua en tu hogar o negocio. Esto nos ayudará a ofrecerte una solución más precisa y personalizada. ¡Estamos aquí para ayudarte a mejorar la calidad de tu agua!",
  },  
];
// const blogData = [
//   {
//     title: "The Benefits of Mindfulness Meditation for Stress and Anxiety",
//     thumbUrl: "images/home_1/post_1.jpeg",
//     date: "May 1, 2023",
//     btnText: "Learn More",
//     href: "/blog/blog-details",
//     socialShare: true,
//   },
//   {
//     title: "Healthy Eating on a Budget: Tips and Strategies",
//     thumbUrl: "images/home_1/post_2.jpeg",
//     date: "May 4, 2023",
//     btnText: "Learn More",
//     href: "/blog/blog-details",
//     socialShare: true,
//   },
//   {
//     title: "The Importance of Regular Cancer Screenings and Early Detection",
//     thumbUrl: "images/home_1/post_3.jpeg",
//     date: "May 1, 2023",
//     btnText: "Learn More",
//     href: "/blog/blog-details",
//     socialShare: true,
//   },
// ];
// const awardData = [
//   {
//     title: "Malcolm Baldrige National Quality Award",
//     subTitle:
//       "This award recognizes healthcare organizations that have demonstrated excellence in leadership, strategic planning, customer and employee satisfaction, and operational efficiency.",
//     iconUrl: "images/icons/award.svg",
//   },
//   {
//     title: "HIMSS Davies Award",
//     subTitle:
//       "This award recognizes healthcare organizations that have used health information technology to improve patient outcomes and reduce costs.",
//     iconUrl: "images/icons/award.svg",
//   },
//   {
//     title: "Healthgrades National’s Best Hospital",
//     subTitle:
//       "This recognition is given to hospitals that have achieved high ratings for clinical quality and patient safety across multiple specialties and procedures.",
//     iconUrl: "images/icons/award.svg",
//   },
//   {
//     title: "Joint Commission Gold Seal of Approval",
//     subTitle:
//       "This recognition is given to hospitals that have met rigorous standards for patient safety and quality of care.",
//     iconUrl: "images/icons/award.svg",
//   },
// ];
const departmentData = [
  {
    title: "Sistema Osmosis Inversa",
    iconUrl: "images/home_1/osmosis_invertido.png",
    href: "/",
  },
  {
    title: "Ablandadores",
    iconUrl: "images/home_1/ablandador.png",
    href: "/",
  },
  {
    title: "Filtros de Carbón",
    iconUrl: "images/home_1/filtro-de-carbon-activado.png",
    href: "/",
  },
  {
    title: "Desinfección",
    iconUrl: "images/home_1/desinfectacion.png",
    href: "/",
  },
  {
    title: "Accesorios",
    iconUrl: "images/home_1/accesorios.png",
    href: "/",
  },
];

export default function Home() {
  pageTitle("Inicio");
  return (
    <>
      <Hero
        title="Tu socio en filtración y pureza de agua"
        subTitle="Estamos comprometidos a brindarte los mejores sistemas de tratamiento de agua, incluyendo filtros y osmosis inversa, para garantizar agua pura y segura, mejorando tu calidad de vida y bienestar."
        bgUrl="/images/home_1/hero_bg.jpeg"
        imgUrl="/images/home_1/mujer-joven-blusa-azul-bebiendo-vaso-agua.png"
        videoBtnText="Mira como trabajamos"
        videoUrl="https://www.youtube.com/embed/ty4VnhvfdE0?si=HbuvR2ToOpxMsgCg"
        infoList={[
          {
            title: "Linea directa",
            subTitle: "829-369-1501",
            iconUrl: "/images/contact/icon_1.svg",
          },
          {
            title: "Linea técnica",
            subTitle: "849-206-6969",
            iconUrl: "/images/contact/icon_1.svg",
          },
          {
            title: "Lugar",
            subTitle: "Santo Domingo, RD",
            iconUrl: "/images/icons/pin.svg",
          },
        ]}
        btnText="Reserve ahora"
        btnUrl="/citas"
      />
      {/* Start Feature Section */}
      <Section
        topMd={185}
        topLg={140}
        topXl={100}
        bottomMd={185}
        bottomLg={140}
        bottomXl={100}
      >
        <FeaturesSection
          sectionTitle="Nuestros valores"
          data={featureListData}
        />
      </Section>
      {/* End Feature Section */}
      {/* Start About Section */}
      <Section>
        <AboutSection
          imgUrl="/images/home_1/hombre-haciendo-limonada-fuera.png"
          spiningImgUrl="/images/home_1/about_mini.svg"
          title="Nuestros valores"
          subTitle="INGET AGUA"
          featureList={[
            {
              featureListTitle:
                "INGET AGUA es un equipo de técnicos especializados en sistemas de filtración y osmosis inversa.",
              featureListSubTitle:
                "Dedicados a ofrecer soluciones de purificación de agua de la más alta calidad. Creemos en un enfoque integral que garantiza la pureza del agua, abordando no solo los contaminantes, sino proporcionando soluciones completas para la salud y seguridad del agua.",
            },
          ]}
        />
      </Section>
      {/* End About Section */}
      {/* Start Departments Section */}
      <Section topMd={185} topLg={150} topXl={110}>
        <DepartmentSection
          sectionTitle="Productos"
          bgUrl="images/home_1/department_bg.svg"
          data={departmentData}
        />
      </Section>

      {/* End Departments Section */}
      {/* Start Award Section 
      <Section topMd={185} topLg={140} topXl={100}>
        <AwardSection sectionTitle="Productos" data={awardData} />
      </Section>*/}
      {/* End Award Section */}
      {/* Start Testimonial */}
      <Section
        topMd={185}
        topLg={140}
        topXl={100}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
      >
        <TestimonialSection
          sectionTitle="Algunas reseñas"
          sectionTitleDown="DE NUESTROS CLIENTES"
        />
      </Section>
      {/* End Testimonial */}
      {/* Start Banner Section */}
      <Section>
        <Banner
          bgUrl="images/home_1/cta_bg.svg"
          imgUrl="images/home_1/cta_img.png"
          title="¡No dejes que la calidad de tu agua quede en segundo plano!"
          subTitle="¡Programa una cita con uno de nuestros técnicos expertos en tratamiento de agua hoy mismo!"
        />
      </Section>
      {/* End Banner Section */}
      {/* Start Blog Section 
      <Section topMd={190} topLg={145} topXl={105}>
        <BlogSection
          sectionTitle="Latest Update"
          sectionTitleUp="BLOG POSTS"
          data={blogData}
        />
      </Section>
      */}
      {/* End Blog Section */}
      {/* Start Appointment Section */}
      <Section topMd={190} topLg={145} topXl={105} id="appointment">
        <AppointmentSection
          sectionTitleUp="RESERVA UNA"
          sectionTitle="Cita"
          imgUrl="/images/home_1/appointment.jpeg"
        />
      </Section>
      {/* End Appointment Section */}
      {/* Start FAQ Section */}
      <Section topMd={190} topLg={145} topXl={105}>
        <FaqSection
          data={faqData}
          sectionTitle="normalmente se pregunta"
          sectionTitleUp="QUÉ GENTE"
        />
      </Section>
      {/* End FAQ Section */}
      {/* Start Brand Section */}
      <Section
        topMd={200}
        topLg={150}
        topXl={110}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
      >
        <BrandsSection data={brandData} />
      </Section>
      {/* End Brand Section */}
    </>
  );
}
